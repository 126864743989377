.customTransition-enter {
    opacity: 0;
    transform: scale(0.9);
}

.customTransition-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.customTransition-exit {
    opacity: 1;
}

.customTransition-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}
