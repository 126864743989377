.chatBodyWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    width: 100%;
    height: calc(100vh - 60px - 60px - 98px);
    padding-top: 16px;
}

.generalMessageWrapper {
    width: 100%;
    margin-bottom: 24px;
}

.userMessageWrapper {
    display: flex;
    justify-content: flex-end;
    padding-right: 16px;
}

.assistantMessageWrapper {
    display: flex;
    justify-content: flex-start;
    padding-left: 16px;
}

.userMessage {
    display: flex;
    align-items: center;
    text-align: start;
    color: #142739;
    padding: 16px;
    border-radius: 20px 20px 0 20px;
    max-width: 70%;
    height: fit-content;
    background-color: #F2F6F6;
}

.assistantMessage {
    display: flex;
    align-items: center;
    text-align: start;
    color: white;
    padding: 16px;
    border-radius: 20px 20px 20px 0;
    max-width: 70%;
    min-width: 18px;
    height: fit-content;
    background-color: #003399;
}

.content {
    font-family: "Helvetica Neue", serif;
    font-weight: 400;
    font-size: 16px;
}
