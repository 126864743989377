.primaryProductBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 428px;
    width: 892px;
    margin-top: 64px;
    padding-left: 12px;
}

.title {
    font-family: "Helvetica", sans-serif;
    font-size: 44px;
    font-weight: 700;
    line-height: 46px;
}

.bottomSection {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 858px;
    height: 400px;
}

.imagePlaceholder {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 438px;
    max-height: 100%;
}

.productInfoWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 420px;
    height: 80%;
}

.productName {
    text-align: start;
    font-family: "Helvetica", serif;
    font-size: 30px;
    font-weight: 700;
}

.productPriceWrapper {
    display: flex;
    flex-direction: column;
    text-align: start;
    font-family: "Helvetica", serif;
    font-size: 30px;
    font-weight: 700;
}

.msrpSpan {
    text-align: start;
    font-family: "Helvetica", serif;
    font-size: 11px;
    font-weight: 500;
    line-height: 8px;
}

.productPrice {
    text-align: start;
    font-family: "Helvetica", serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
}

.quoteButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 137px;
    height: 36px;
    cursor: pointer;
    background-color: #003399;
}

.quoteButtonTitle {
    font-family: 'Helvetica Neue', serif;
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding-left: 14px;
}

.quoteButtonImage {
    padding-right: 14px;
}

.buyButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 142px;
    height: 24px;
    cursor: pointer;
}

.buyButtonTitle {
    font-family: 'Helvetica Neue', serif;
    color: #003399;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}
