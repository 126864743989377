.initialScreenWrapper {
    display: flex;
    flex-direction: column;
}

.wrapper {
    min-height: 630px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("../../../public/start-screen-image.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.title {
    color: white;
    font-size: 44px;
    font-weight: 700;
    margin-bottom: 16px;
}

.subTitle {
    width: 690px;
    color: white;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 40px;
}

.inputWrapper {
    position: relative;
    display: flex;
    width: 800px;
    height: 56px;
    margin-bottom: 36px;
}

input {
    outline: none;
}

.input {
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    border: unset;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    border-radius: 30px;
}

.sendButton {
    position: absolute;
    right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
}

.sendIcon {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.suggestionWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 984px;
    height: 35px;
}

.innovativeProductsWrapper {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.innovativeProductsText {
    display: flex;
    font-weight: 400;
    font-size: 30px;
    justify-content: space-between;
}

.innovativeProducts {
    margin-top: 30px;
    display: flex;
    width: 90%;
    justify-content: space-between;
}
