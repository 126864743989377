.chatHeader {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    box-shadow: 0 4px 8px -2px #ebebeb;
}

.title {
    font-weight: 700;
    font-size: 21px;
    line-height: 23px;
    padding-left: 32px;
}
