.chatBottomWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
}

.text {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 11px;
    line-height: 14px;
    color: #656565;
    margin-top: 10px;
    margin-bottom: 16px;
    position: absolute;
    z-index: -10;
    bottom: 50px;
}

.inputWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 100%;
    box-shadow: 0 2px 10px 0 #00000014;
}

.input {
    padding-left: 16px;
    padding-right: 54px;
    width: 388px;
    height: 40px;
    border: none;
    margin: 0 16px;
    background-color: #F2F6F6;
    border-radius: 20px;
    font-weight: 500;
    font-size: 16px;
    font-family: "Helvetica Neue", sans-serif;
    color: #656565;
}

.sendIcon {
    cursor: pointer;
    position: absolute;
    right: 30px;
    width: 20px;
    height: 20px;
}
