.bestSellerItemBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: fit-content;
    width: 280px;
    background-color: white;
}

.imagePlaceholder {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 280px;
    max-height: 145px;
    margin-bottom: 12px;
}

.productInfoWrapper {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    width: 236px;
    height: 132px;
}

.productName {
    text-align: start;
    font-family: "Helvetica", serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
}

.productPriceWrapper {
    display: flex;
    flex-direction: column;
    text-align: start;
    font-family: "Helvetica", serif;
    font-size: 30px;
    font-weight: 700;
}

.msrpSpan {
    text-align: start;
    font-family: "Helvetica", serif;
    font-size: 11px;
    font-weight: 500;
    line-height: 8px;
}

.productPrice {
    text-align: start;
    font-family: "Helvetica", serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
}

.quoteButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 76px;
    height: 20px;
    cursor: pointer;
    background-color: #616161;
}

.quoteButtonTitle {
    font-family: 'Helvetica Neue', serif;
    color: white;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    padding: 3px 8px;
}

