.dotElastic {
    margin-left: 4px;
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: white;
    color: white;
    animation: dotElastic 1s infinite linear;
}

.dotElastic::before, .dotElastic::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
}

.dotElastic::before {
    left: -15px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: white;
    color: white;
    animation: dotElasticBefore 1s infinite linear;
}

.dotElastic::after {
    left: 15px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: white;
    color: white;
    animation: dotElasticAfter 1s infinite linear;
}

@keyframes dotElasticBefore {
    0% {
        transform: scale(1, 1);
    }
    25% {
        transform: scale(1, 1.5);
    }
    50% {
        transform: scale(1, 0.67);
    }
    75% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(1, 1);
    }
}

@keyframes dotElastic {
    0% {
        transform: scale(1, 1);
    }
    25% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1, 1.5);
    }
    75% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(1, 1);
    }
}

@keyframes dotElasticAfter {
    0% {
        transform: scale(1, 1);
    }
    25% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1, 0.67);
    }
    75% {
        transform: scale(1, 1.5);
    }
    100% {
        transform: scale(1, 1);
    }
}
