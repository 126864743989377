.animationWrapper {
    position: absolute;
    display: flex;
    left: 0;
    top: -100px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 75%;
    margin-top: 98px;
    overflow-y: scroll;
}

.productsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75%;

    margin-top: 98px;

    overflow-y: scroll;
}


