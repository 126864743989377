.primaryProductBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 480px;
    width: 892px;
    margin-top: 64px;
    margin-bottom: 64px;
    padding-left: 12px;
}

.title {
    font-family: "Helvetica", sans-serif;
    font-size: 44px;
    font-weight: 700;
    line-height: 46px;
}

.bottomSection {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 858px;
    height: 400px;
}

.leftSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.imagePlaceholder {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 438px;
    max-height: 100%;
}

.productInfoWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 438px;
    height: 90%;
}

.productName {
    text-align: start;
    font-family: "Helvetica", serif;
    font-size: 30px;
    font-weight: 700;
}

.productPriceWrapper {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    text-align: start;
    font-family: "Helvetica", serif;
    font-size: 30px;
    font-weight: 700;
}

.msrpSpan {
    text-align: start;
    font-family: "Helvetica", serif;
    font-size: 11px;
    font-weight: 500;
    line-height: 8px;
}

.productPrice {
    text-align: start;
    font-family: "Helvetica", serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
}

.rightSection {
    width: 340px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.formTitleWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 30px;
}

.formTitle {
    text-align: start;
    font-family: 'Helvetica Neue', serif;
    font-weight: 400;
    font-size: 30px;
    line-height: 24px;
}

.formSubTitle {
    margin-top: 4px;
    text-align: start;
    font-family: 'Helvetica Neue', serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.finalWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 30px;
}

.finalTitle {
    text-align: start;
    font-family: 'Helvetica Neue', serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
}

.finalSubTitle {
    text-align: start;
    font-family: 'Helvetica Neue', serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
}

.input {
    height: 36px;
    width: 100%;
    font-size: 16px;
    padding-left: 16px;
    margin-bottom: 16px;
}

.inputMessage {
    resize: none;
    height: 100px;
    width: 100.5%;
    font-size: 16px;
    text-align: start;
    align-items: flex-start;
    padding-top: 16px;
    padding-left: 16px;
    border-color: #808080 !important;
    border-radius: 2px;
}

.inputMessage:focus {
    outline: none !important;
}



.quoteButton {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 137px;
    height: 40px;
    cursor: pointer;
    background-color: #003399;
}

.quoteButtonTitle {
    font-family: 'Helvetica Neue', serif;
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding-left: 14px;
}

.quoteButtonImage {
    padding-right: 14px;
}

.buyButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 142px;
    height: 24px;
    cursor: pointer;
}

.buyButtonTitle {
    font-family: 'Helvetica Neue', serif;
    color: #003399;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}
