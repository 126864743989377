.wrapper {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    width: 200px;
    height: 160px;
    padding: 12px 16px 12px 16px;
}

.image {
    max-width: 100px;
    max-height: 100px;
    width: auto;
    height: auto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.text {
    margin-top: 16px;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
}
