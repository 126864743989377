.suggestionWrapper {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 15px;
    width: fit-content;
    padding: 12px 16px 12px 16px;
}

.suggestionText {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}
