.bestSellersBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 428px;
    width: 892px;
    margin-top: 40px;
    padding-left: 12px;
}

.title {
    font-family: "Helvetica", sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 32px;
}

.items {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 40px;
}
