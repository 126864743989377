.tableStyle {
    margin-top: 36px;
    border-collapse: collapse;
    width: 80%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 80px #0033990F;
}

.tableHeaderStyle {
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    background-color: #003399;
    border-bottom: 1px solid #ddd;
    padding: 15px;
    text-align: center;
}

.tableCellStyle {
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid #ddd;
    padding: 15px;
    background-color: #fff;
}

.image {
    max-width: 180px;
    max-height: 145px;
}
