.animationWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.animation {
    height: 200px;
}

.titleWrapper {
    top: 500px;
    position: absolute;
    display: flex;
    flex-direction: column;
}

.title {
    font-family: "Helvetica", sans-serif;
    font-size: 30px;
    font-weight: 600;
    line-height: 46px;
}
