.primaryProductBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 892px;
    padding-left: 12px;
}

.title {
    font-family: "Helvetica", sans-serif;
    font-size: 44px;
    font-weight: 700;
    line-height: 46px;
    margin-bottom: 36px;
}

.contentWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.video {
    width: 70%;
    height: 450px;
}

.rightSection {
    display: flex;
    align-items: center;
    width: 858px;
    height: 365px;
}

.descriptionItem {
    margin-top: 12px;
    margin-left: 32px;
    text-align: start;
    font-size: 20px;
    font-weight: 600;
}

.descriptionItemHighlighted {
    margin-top: 12px;
    margin-left: 32px;
    text-align: start;
    font-size: 20px;
    font-weight: 800;
}
