.chatWrapper {
    position: fixed;
    margin-top: 98px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 420px;
    height: calc(100vh - 98px);
    box-shadow: 0 0 8px #ebebeb;
    background-color: white;
}

.chatPlaceholder {
    margin-top: 98px;
    width: 420px;
    height: 764px;
}
