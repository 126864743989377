.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-float infinite 3s ease-in-out;
    }
}

.content {
    width: 100%;
    height: 100%;
}

.second-screen-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: fit-content;
}

.App-header {
    position: fixed;
    width: 100%;
    background-color: #19212b;
    height: 98px;
    display: flex;
    flex-direction: column;
    z-index: 1000;
}

.logo {
    height: 48px;
    width: 150px;
    padding: 25px 0 25px 150px;
}

.App-link {
    color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(0px);
    }
}

.chat-body {
    width: 300px;
    height: 400px;
    border: 1px solid #ccc;
    display: flex;
    flex-direction: column;
}

.messages-container {
    flex: 1;
    overflow-y: scroll;
    padding: 10px;
}

.message {
    padding: 8px 12px;
    margin-bottom: 5px;
    border-radius: 10px;
    max-width: 70%;
}

.user {
    align-self: flex-end;
    background-color: #e6f2ff; /* Light blue for user messages */
}

.assistant {
    align-self: flex-start;
    background-color: #f0f0f0; /* Light gray for bot messages */
}

.input-container {
    display: flex;
    align-items: center;
    padding: 10px;
}

.input-container input {
    flex: 1;
    padding: 8px;
    margin-right: 10px;
}

.input-container button {
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.input-container button:hover {
    background-color: #0056b3;
}
